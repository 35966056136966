.audit-modal .audit-table {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; // For momentum scroll on mobile devices
  }
  
  @media only screen and (max-width: 768px) { // Adjust the max-width as needed
    .audit-modal table {
      width: 100%;
      display: block;
    }
    
    .audit-modal th, .audit-modal td {
      white-space: nowrap;
    }

    .audit-modal .audit-table th, .audit-modal .audit-table td {
        padding: 8px 16px; /* Increase padding for better touch targets on mobile */
    }
  }

  .audit-modal .audit-table table {
    border-collapse: collapse; /* Ensures that the borders are crisp */
    width: 100%; /* Full width of the modal content */
  }
  
  .audit-modal .audit-table th {
    position: sticky;
    top: 0;
    background-color: #000; /* Black background for headers */
    color: #fff; /* White text for contrast */
    padding: 8px; /* Some padding for spacing */
    text-align: left; /* Align the text to the left */
    border-bottom: 1px solid #ddd; /* A border below each header for separation */
  }
  
  .audit-modal .audit-table td {
    padding: 8px; /* Consistent padding with the headers */
    border-bottom: 1px solid #ddd; /* Borders between rows */
  }
  
  .audit-modal .audit-table tr:nth-child(even) {
    background-color: #f2f2f2; /* Alternate shading for rows */
  }

.audit-modal .filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.audit-modal .filter-dropdown {
  position: relative;
  font-family: Arial, sans-serif; // or your preferred font
  padding: 5px 70px 10px 0px;
}

.audit-modal .dropdown-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 11px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  height: 42px;
}

.audit-modal .checkbox-list {
  position: absolute;
  top: 100%; // Positioned right below the dropdown button
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100; // Make sure it's above other elements
  width: max-content;
  margin-top: 2px; // A little space between the button and dropdown

  .list {
    
    max-height: 208px;
    overflow-y: auto;
    
    .label{
      display: flex;

      label{
        display: flex !important;
      }

      .checkbox-text{
        margin-left: 7px !important;
      }
    }
  }
}

.audit-modal .checkbox-list label {
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
}

.audit-modal .checkbox-list input[type='checkbox'] {
  margin-right: 5px;
}

.audit-modal .filter-dropdown-actions {
  display: flex;
  padding-top: 10px;
}

.audit-modal .filter-dropdown-button {
  padding: 5px 15px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #e7e7e7;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  &:hover {
    background-color: #d4d4d4;
  }
}

.dropdown-icon{
  margin-left: 8px;
}
