$green-color: #00b480;
$red-color: #d90400;
$black-color: #2e2e37;
$grey-color: #a2a7b8;

@mixin members-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #222222;
  font-family: 'bebas-neue-by-fontfabric', 'Helvetica Neue', 'Helvetica',
    sans-serif;
  text-transform: uppercase;
}
