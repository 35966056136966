@import '../../../styles/variables';

.member-form-panel {
  width: 100%;
  position: relative;

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
  }
}

.create-member-modal {
  .ant-modal-content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
  }

  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    padding: 0;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-title {
    @include members-title;
  }

  .ant-modal-close {
    color: $black-color;
    top: 32px;
    right: 32px;
  }

  .ant-modal-close-x {
    font-size: 24px;
    width: auto;
    height: auto;
    line-height: initial;
  }

  .ant-modal-footer {
    border-top: none;
    text-align: center;
  }
}
