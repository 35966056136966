@import '../../../styles/variables';

.subscription-form {
  margin-top: 40px;

  .btn-submit {
    margin-top: 20px;
    height: 40px;
    padding: 0;
    height: 48px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    box-shadow: none;

    &.loading {
      pointer-events: none;

      img {
        width: 32px;
        height: 32px;
        margin-top: -6px;
      }
    }
  }

  .member-form-control {
    width: 100%;
    margin-top: 10px;

    label {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 24px;
      color: $black-color;
    }
  }
}
