@import '../../../styles/variables';

$dark-green-color: #00986c;
$grey-color: #494c56;

.members-table-container {
  overflow: auto;
  margin: 0 -20px;
  position: relative;

  @media (min-width: 1200px) {
    margin: 0;
  }
}

.access{
  flex-direction: column;
  place-self: flex-start;
  border-left: 1px solid white;
  border-right: 1px solid white;
  height: 100%;
}

.subscription{
  flex-direction: column;
  place-self: flex-start;
  border-right: 1px solid white;
  height: 100%;
}

.subscription-header, .access-header {
  width: 100% !important;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
}

.subscription-subitems{
  display: grid !important;
  grid-template-columns: repeat(5, 110px);
  width: 100%;
  .centered{
    justify-self: center;
    text-align: center;
  }
}

.access-subitems{
  display: grid !important;
  width: 100%;
  grid-template-columns: repeat(4, 110px);
  .centered{
    justify-self: center;
    text-align: center;
  }

  &.display-operational-reports {
    grid-template-columns: repeat(5, 110px);
  }
}

.done-button {
  width: 100% !important;
  height: 48px;
  padding: 0% !important;
  margin-top: 40px;
  box-shadow: none;
  overflow: hidden;
  font-size: 16px;
  line-height: 28px;

  @media (min-width: 1200px) {
    width: auto;

    &.sm {
      display: none;
    }

    &.xl {
      display: block;
    }
  }

  span {
    margin: 0 auto;
  }
}

.members-table {
  display: flex;
  flex-direction: column;
  width: max-content;
  border-radius: 4px;
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.118122);
  overflow: auto;
  max-height: 600px;

  @media (min-width: 1400px) {
    max-height: 1000px;
  }

  @media (min-width: 2045px) {
    width: 100%;
    max-height: 1000px;
  }

  .table-head,
  .table-body {
    overflow: visible;
  }

  .row {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 440px 550px 92px;
    padding: 0 16px 0 24px;
    box-shadow:
      0px -1px 0px #eaeaea,
      0px 1px 0px #eaeaea;

      &.display-operational-reports{
        grid-template-columns: 2fr 3fr 2fr 550px 550px 92px;
      }

    @media (min-width: 1400px) {
      grid-template-columns: 2fr 3fr 2fr 440px 550px 172px;

      &.display-operational-reports{
        grid-template-columns: 2fr 3fr 2fr 550px 550px 172px;
      }
    }

    &:first-child,
    &:last-child {
      box-shadow: none;
    }

    &.head {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #16161a;

      & > div {
        font-weight: 700;
        font-size: 12px;
        color: white;
        text-transform: uppercase;
        padding: 16px 0;
      }
    }

    &:nth-child(2n) {
      background-color: white;
    }

    $padding: 12px;
    $line-height: 28px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $padding 0;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 14px;
      line-height: $line-height;
      color: $black-color;
      overflow: hidden;

      &.centered {
        justify-content: center;
        text-align: center;
      }

      &.phone {
        padding-left: 16px;
      }

      &.buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
      }

      &.organisation-name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .organisation-name {
          font-size: 14px;
        }
      }
    }

    .grid-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      box-shadow: none;

      &.grey {
        background-color: $grey-color;
        border-color: $grey-color;
        color: white;

        &:hover {
          background-color: darken($color: $grey-color, $amount: 10%);
          border-color: darken($color: $grey-color, $amount: 10%);
        }
      }

      &.grey-outlined {
        background-color: white;
        border-color: $grey-color;
        color: $grey-color;

        &:hover {
          background-color: $grey-color;
          border-color: $grey-color;
          color: white;
        }

        &.delete:hover,
        &.delete.pending {
          background-color: $red-color;
          border-color: $red-color;
          color: white;
        }
      }

      span {
        display: none;
      }

      @media (min-width: 1200px) {
        width: 80px;
        height: 48px;

        span {
          display: block;
        }

        i {
          display: none;
        }
      }
    }

    .member-phone-input {
      max-width: fit-content;

      .form-control {
        padding: 12px 16px;
        width: 138px;
        font-weight: 600;
        font-size: 14px;

        @media (min-width: 1200px) {
          font-size: 16px;
        }
      }

      &.disabled {
        .form-control {
          background-color: transparent;
          border: none;
          font-weight: 400;
        }
      }

      &.input-validation-error {
        .form-control {
          border-color: $red-color;
        }
      }
    }
  }
}

.error-phone-tooltip {
  max-width: 282px !important;

  .ant-tooltip-content {
    box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);

    .ant-tooltip-arrow {
      width: 16px;
      height: 16px;
      top: 0;
      transform: translateX(-50%) rotate(45deg);

      .ant-tooltip-arrow-content {
        width: 16px;
        height: 16px;
        box-shadow: none;
        transform: none;
      }
    }
  }

  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    color: $red-color;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 282px;
    height: 48px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.118122);
    border-radius: 4px;
  }
}

.searchInput {
  border: none !important;
  box-shadow: none !important;
}

.radioButtonBox {
  height: 200px;
  width: 300px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 20px;
}

.radioButtonBorderBox {
  border: 2px solid black;
  background-color: white;
  border-radius: 25px;
  width: fit-content;
  overflow: hidden;
}

.errorMessage {
  font-weight: 700;
  font-size: medium;
  color: rgba(255, 0, 0, 0.925);
}

.confirm-message-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  align-items: stretch;

  h4 {
    @include members-title;
    text-align: center;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black-color;
  }

  .buttons-block {
    display: flex;
    flex-direction: row;
    gap: 16px;

    button {
      height: 64px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      box-shadow: none;

      &.no{
        color:  #494c56 !important;
        border-color: #494c56 !important;

      &:hover{
          background-color: #494c56 !important;
          color: white !important;
        }
      }

      &.yes{
        color:  red !important;
        border-color: red !important;

      &:hover{
          background-color: red !important;
          color: white !important;
        }
      }
    }
  }
}