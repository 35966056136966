@import '../../../styles/variables';

$grey-color: #494c56;

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
}

.btn-crud {
  display: flex !important;
  margin-top: 20px;
  margin-left: 16px;
  padding: 0;
  padding-top: 16px;
  height: 48px;
  text-align: center;
  font-weight: 700;
  box-shadow: none !important;
  align-items: center;

  &.grey {
    background-color: $grey-color;
    border-color: $grey-color;
    color: white;

    &:hover {
      background-color: darken($color: $grey-color, $amount: 10%);
      border-color: darken($color: $grey-color, $amount: 10%);
    }
  }

  &.grey-outlined {
    background-color: white;
    border-color: $grey-color;
    color: $grey-color;

    &:hover {
      background-color: $grey-color;
      border-color: $grey-color;
      color: white;
    }

    &.delete:hover,
    &.delete.pending {
      background-color: $red-color;
      border-color: $red-color;
      color: white;
    }
  }

  &.loading {
    pointer-events: none;

    img {
      width: 32px;
      height: 32px;
      margin-top: -6px;
    }
  }
}

.member-phone-input {
  max-width: fit-content;

  .form-control {
    padding: 12px 16px;
    width: 138px;
    font-weight: 600;
    font-size: 14px;

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  &.disabled {
    .form-control {
      background-color: transparent;
      border: none;
      font-weight: 400;
    }
  }

  &.input-validation-error {
    .form-control {
      border-color: $red-color;
    }
  }
}
