.member-phone-input-container {
  width: 100%;

  .flag-dropdown {
    display: none;
  }

  .form-control {
    padding: 10px;
    height: 48px;
    width: 100%;
    border-color: #cbcfde;
    color: #494c56;
    background-color: #ffffff;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1rem;
  }
}
