@import '../../styles/variables';

.members-page-container {
  background-color: #f2f2f2;
  overflow: hidden;
  height: 100%;

  @media (min-width: 1200px) {
    overflow-y: auto;
  }

  .members-page-message {
    margin-top: 1em;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #494c56;

    @media (min-width: 1200px) {
      line-height: 24px;
    }
  }

  .refresh-block {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin: 0 -20px;

    @media (min-width: 1200px) {
      padding: 24px 24px 24px 32px;
      margin: 0;
      width: 100%;
    }

    .refresh-block-heading {
      @include members-title;
      margin: 0;
    }

    .refresh-button {
      width: 92px;
      height: 48px;
      padding: 5px 10px;
      box-shadow: none;
      overflow: hidden;
      font-size: 16px;
      line-height: 24px;

      &.sm {
        display: block;
      }

      &.xl {
        display: none;
      }

      @media (min-width: 1200px) {
        width: auto;

        &.sm {
          display: none;
        }

        &.xl {
          display: block;
        }
      }

      span {
        margin: 0 auto;
      }
    }

    .audit-button {
      color: #494c56;
      border: 2px solid #494c56;
      width: 92px;
      height: 48px;
      padding: 5px 10px;
      box-shadow: none;
      overflow: hidden;
      font-size: 16px;
      line-height: 24px;
      margin-left: 10px;
    
      &:hover {
        background-color: #494c56;
        color: white;
      }
    
      &.sm {
        display: block;
      }
    
      &.xl {
        display: none;
      }
    
      @media (min-width: 1200px) {
        width: auto;
    
        &.sm {
          display: none;
        }
    
        &.xl {
          display: block;
        }
      }
    
      span {
        margin: 0 auto;
      }
    }

    .create-button {
      width: 308px;
      height: 48px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 12px;
      box-shadow: none;
      font-size: 16px;
      line-height: 24px;
      margin-right: 10px;

      span {
        margin: 0 !important;
        font-weight: 700;
      }

      i {
        font-weight: 900;
      }
    }

    .button-box{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      .button-box {
        flex-direction: column;
      }
    
      .button-box {
        .refresh-button, .audit-button, .create-button {
          width: 308px !important;
          margin-bottom: 8px;
          margin-left: 0px;
        }
      } 

      .refresh-block{
        flex-direction: column !important;
      }
    }
  }

  @media (max-width: 768px) {
    .refresh-block{
      flex-direction: column !important;
      align-items: flex-start;
    }
  }
}

.success-block-container {
  .title-container {
    &::after {
      content: ' ';
      border-bottom: 2px solid $green-color;
      width: 100%;
      position: relative;
      z-index: 1;
      top: -26px;
    }
  }
}

.error-block-container {
  .title-container {
    &::after {
      content: ' ';
      border-bottom: 2px solid $red-color;
      width: 100%;
      position: relative;
      z-index: 1;
      top: -26px;
    }
  }
}

.success-block-container,
.error-block-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  position: relative;

  .title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 52px;

    .title {
      @include members-title;
      padding: 10px;
      position: absolute;
      z-index: 2;
      background-color: white;
      display: flex;
      flex-direction: row;
      gap: 10px;

      img {
        position: relative;
        margin-top: -3px;
      }
    }
  }
}

.notification-modal {
  .ant-modal-body {
    padding: 32px;
  }
}

.confirm-message-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  align-items: stretch;

  h4 {
    @include members-title;
    text-align: center;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black-color;
  }

  .buttons-block {
    display: flex;
    flex-direction: row;
    gap: 16px;

    button {
      height: 64px;
      max-width: 160px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }
}
