@import '../../../styles/variables';

.success-block-container {
  .title-container {
    &::after {
      content: ' ';
      border-bottom: 2px solid $green-color;
      width: 100%;
      position: relative;
      z-index: 1;
      top: -26px;
    }
  }
}

.error-block-container {
  .title-container {
    &::after {
      content: ' ';
      border-bottom: 2px solid $red-color;
      width: 100%;
      position: relative;
      z-index: 1;
      top: -26px;
    }
  }
}

.success-block-container,
.error-block-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  position: relative;

  .title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 52px;

    .title {
      @include members-title;
      padding: 10px;
      position: absolute;
      z-index: 2;
      background-color: white;
      display: flex;
      flex-direction: row;
      gap: 10px;

      img {
        position: relative;
        margin-top: -3px;
      }
    }
  }
}

.notification-modal {
  .ant-modal-body {
    padding: 32px;
  }
}

.confirm-message-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  align-items: stretch;

  h4 {
    @include members-title;
    text-align: center;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black-color;
  }

  .buttons-block {
    display: flex;
    flex-direction: row;
    gap: 16px;

    button {
      height: 64px;
      box-sizing: border-box;
      max-width: 140px !important;
      display: flex;
      align-items: center;
      box-shadow: none;
    }
  }
}
