@import '../../../styles/variables';

.styled-checkbox {
  .ant-checkbox {
    .ant-checkbox-inner {
      border: 2px solid $grey-color;
    }

    input:focus + .ant-checkbox-inner {
      border-color: $green-color;
    }

    &.ant-checkbox-checked {
      &:after {
        border-color: $green-color;
      }

      .ant-checkbox-inner {
        border-color: $green-color;
        background-color: $green-color;

        &::after {
          transform: rotate(45deg) scale(2) translate(-50%, -50%);
        }
      }

      &.ant-checkbox-disabled .ant-checkbox-inner {
        border-color: $grey-color;
        background-color: $grey-color;

        &::after {
          border-color: #f5f5f5;
        }
      }
    }

    &.ant-checkbox-disabled .ant-checkbox-inner {
      border-color: $grey-color !important;
    }
  }

  &:hover .ant-checkbox .ant-checkbox-inner {
    border-color: $green-color;
  }

  &.large {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 32px;
        height: 32px;

        &::after {
          top: 60%;
          left: 32%;
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          &::after {
            transform: rotate(45deg) scale(2) translate(-50%, -50%);
          }
        }
      }
    }
  }

  &.medium {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;

        &::after {
          top: 58%;
          left: 28%;
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          &::after {
            transform: rotate(45deg) scale(1.4) translate(-50%, -50%);
          }
        }
      }
    }
  }
}
