@import '../../../styles/variables';

.border-box {
  border: 1px solid black;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 14px;
  position: relative;
  width: 100%;

  .border-text {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px; 
  }
}

.member-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  position: relative;

  .btn-submit {
    height: 40px;
    padding: 20px 0;
    width: 100%;
    height: 64px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    box-shadow: none;

    &.loading {
      pointer-events: none;

      img {
        width: 32px;
        height: 32px;
        margin-top: -6px;
      }
    }
  }
  
  .member-form-control {
    width: 100%;

    &.checkbox {
      padding: 10px;
    }

    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: $black-color;

      @media (min-width: 1200px) {
        font-size: 16px;
      }
    }

    .hint {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #7b8091;
      margin-bottom: 5px;
    }

    input {
      border-bottom-width: 1px;

      &:focus {
        box-shadow: none;
      }
    }

    &.validation-error {
      input {
        border-color: $red-color;
        background-image: url('../../../assets/images/exclamation-triangle.svg');
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 18px 14px;
        padding-left: 38px;
      }

      .error-message {
        height: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: $red-color;
        width: 100%;
        text-align: center;
        display: inline-block;
      }
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    opacity: 0;
    z-index: 10;
  }

  .asterik {
    color: $red-color;
    font-weight: 800;
  }
}
